import React, { useContext, useEffect, useState } from "react"
import ShopifyClient from "shopify-buy"

import Cookies from "js-cookie"

const SHOPIFY_CHECKOUT_STORAGE_KEY = "shopify_checkout_id"

// @ts-ignore
const client = ShopifyClient.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN,
  domain: "checkout.kattbjoern.de"
})

const initialStoreState = {
  discounts: {},
  shopifyClient: client,
  isAdding: false,
  cartIsOpen: false,
  checkout: {
    lineItems: []
  }
}

export const StoreContext = React.createContext({
  store: initialStoreState,
  setStore: () => null
})

const createNewCheckout = store => {
  return store.shopifyClient.checkout.create()
}

const fetchCheckout = (store, id) => {
  return store.shopifyClient.checkout.fetch(id)
}

export const useDiscountsFromState = () => {
  const {
    store: { discounts }
  } = useContext(StoreContext)
  let finalDiscounts = discounts
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) {
    return finalDiscounts
  }

  const keys = Object.keys(discounts)
  if (keys.length === 0) {
    finalDiscounts = JSON.parse(localStorage.getItem("discounts"))
  }

  return finalDiscounts
}

const setCheckoutInState = (checkout, setStore) => {
  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, checkout.id)
  }

  setStore(prevState => {
    return { ...prevState, checkout: { ...checkout, lineItems: [] } }
  })
}

const removeCheckoutInState = (checkout, setStore) => {
  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    localStorage.removeItem(SHOPIFY_CHECKOUT_STORAGE_KEY, checkout.id)
  }

  setStore(prevState => {
    return { ...prevState, checkout }
  })
}

function useCheckout() {
  const {
    store: { checkout }
  } = useContext(StoreContext)

  // Collect Google Ads params from cookies and append to checkout URL
  // Start by parsing the checkout.webUrl to manage existing parameters

  if (!checkout.webUrl) {
    return
  }

  const checkoutUrl = new URL(checkout.webUrl)

  // Get existing search parameters from the checkout URL
  const existingParams = new URLSearchParams(checkoutUrl.search)

  // Get parameters from the current page URL
  const currentParams = new URLSearchParams(window.location.search)

  // Define parameters from cookies
  const cookieParams = {
    gclid: Cookies.get("gclid"),
    utm_source: Cookies.get("utm_source"),
    utm_medium: Cookies.get("utm_medium"),
    utm_campaign: Cookies.get("utm_campaign"),
    utm_term: Cookies.get("utm_term"),
    utm_content: Cookies.get("utm_content")
  }

  // Function to merge parameters into existing ones
  function mergeParams(sourceParams, targetParams) {
    for (const [key, value] of sourceParams.entries()) {
      if (value && !targetParams.has(key)) {
        targetParams.set(key, value)
      }
    }
  }

  // Merge current URL parameters and cookie parameters into existing parameters
  mergeParams(currentParams, existingParams)
  mergeParams(new URLSearchParams(cookieParams), existingParams)

  // Update the search parameters of the checkout URL
  checkoutUrl.search = existingParams.toString()

  // The fully constructed URL with all parameters
  const fullCheckoutURL = checkoutUrl.toString()

  return () => {
    window.open(fullCheckoutURL, "_self")
  }
}

const StoreContextProvider = ({ children }) => {
  const [store, setStore] = useState(initialStoreState)
  const [initStore, setInitStore] = useState(false)
  const [lineItems, setLineItems] = useState([])

  useEffect(() => {
    if (initStore === false) {
      const initializeCheckout = async () => {
        // Check for an existing cart.
        const isBrowser = typeof window !== "undefined"
        const existingCheckoutId = isBrowser
          ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY)
          : null

        if (existingCheckoutId) {
          try {
            const checkout = await fetchCheckout(store, existingCheckoutId)

            // Make sure none of the items in this cart have been deleted from Shopify.
            if (checkout.lineItems.some(lineItem => !lineItem.variant)) {
              throw new Error(
                "Invalid line item in checkout. This variant was probably deleted from Shopify"
              )
            }

            setLineItems(checkout.lineItems)

            // Make sure this cart hasn’t already been purchased.
            if (!checkout.completedAt) {
              setCheckoutInState(checkout, setStore)
              return
            }
          } catch (e) {
            localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, null)
          }
        }

        const newCheckout = await createNewCheckout(store)
        setCheckoutInState(newCheckout, setStore)
      }
      initializeCheckout()
      setInitStore(true)
    }
  }, [store, setStore, store.shopifyClient.checkout, initStore])

  return (
    <StoreContext.Provider
      value={{
        store,
        setStore,
        lineItems,
        setLineItems
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

function useCartCount() {
  const { lineItems } = useContext(StoreContext)

  let count = 0
  if (lineItems) {
    count = lineItems.reduce(
      (runningTotal, item) => item.quantity + runningTotal,
      0
    )
  }

  return count
}

function useAddItemToCart(cartShouldBeOpened = true) {
  // @ts-ignore
  const {
    store: { checkout, shopifyClient },
    setStore,
    setLineItems
  } = useContext(StoreContext)

  async function addItemToCart(variantId, quantity) {
    if (variantId === "" || !quantity) {
      console.error("Both a size and quantity are required.")
      return
    }

    setStore(prevState => {
      return { ...prevState, isAdding: true }
    })

    const checkoutId = checkout.id
    const lineItemsToAdd = [
      {
        variantId,
        quantity
      }
    ]

    const newCheckout = await shopifyClient.checkout.addLineItems(
      checkoutId,
      lineItemsToAdd
    )

    setLineItems(newCheckout.lineItems)

    setStore(prevState => {
      return {
        ...prevState,
        checkout: newCheckout,
        cartIsOpen: cartShouldBeOpened,
        isAdding: false
      }
    })
  }
  return addItemToCart
}

function useRemoveItemFromCart() {
  const {
    store: { checkout, shopifyClient },
    setStore,
    setLineItems
  } = useContext(StoreContext)

  async function removeItemFromCart(itemId) {
    const newCheckout = await shopifyClient.checkout.removeLineItems(
      checkout.id,
      [itemId]
    )

    setLineItems(newCheckout.lineItems)

    setStore(prevState => {
      return { ...prevState, checkout: newCheckout }
    })
  }

  return removeItemFromCart
}

function useClearAllFromCart() {
  const {
    store,
    store: { checkout },
    setStore
  } = useContext(StoreContext)
  return () => {
    removeCheckoutInState(checkout, setStore)
    setCheckoutInState(createNewCheckout(store), setStore)
  }
}

function useToggleCart() {
  const {
    store: { cartIsOpen },
    setStore
  } = useContext(StoreContext)

  async function toggleCart() {
    setStore(prevState => {
      return { ...prevState, cartIsOpen: !cartIsOpen }
    })
  }

  return toggleCart
}

function useStore() {
  const { store } = useContext(StoreContext)
  return store
}

function useCartItems() {
  const { lineItems } = useContext(StoreContext)

  return lineItems
}

function useCartTotals() {
  const {
    store: { checkout }
  } = useContext(StoreContext)

  const tax = checkout.totalTaxV2
    ? `${Number(checkout.totalTaxV2.amount).toFixed(2)}`
    : "-"
  const total = checkout.totalPriceV2
    ? `${Number(checkout.totalPriceV2.amount).toFixed(2)}`
    : "-"
  const totalBeforeDiscounts = checkout.lineItemsSubtotalPrice
    ? `${Number(checkout.lineItemsSubtotalPrice.amount).toFixed(2)}`
    : "-"

  const totalSavingsResult =
    checkout.lineItemsSubtotalPrice && checkout.totalPriceV2
      ? checkout.lineItemsSubtotalPrice.amount - checkout.totalPriceV2.amount
      : 0

  const totalSavings = totalSavingsResult
    ? `${Number(totalSavingsResult).toFixed(2)}`
    : "-"

  return {
    tax,
    total,
    totalSavings,
    totalBeforeDiscounts
  }
}

function useUpdateItemsFromCart() {
  const {
    store: { checkout, shopifyClient },
    setStore,
    setLineItems
  } = useContext(StoreContext)

  async function updateItemsFromCart(items) {
    items = [].concat(items)
    const newCheckout = await shopifyClient.checkout.updateLineItems(
      checkout.id,
      items
    )

    setLineItems(newCheckout.lineItems)

    setStore(prevState => {
      return { ...prevState, checkout: newCheckout }
    })
  }

  return updateItemsFromCart
}

export {
  client,
  StoreContextProvider,
  useAddItemToCart,
  useStore,
  useCartCount,
  useCartItems,
  useCartTotals,
  useRemoveItemFromCart,
  useUpdateItemsFromCart,
  useCheckout,
  useToggleCart,
  useClearAllFromCart
}
