import { createClient } from "@sanity/client"
import { getGatsbyImageData } from "gatsby-source-sanity"

const projectId = process.env.GATSBY_SANITY_PROJECT_ID
const envDataset = process.env.GATSBY_SANITY_DATASET
const apiVersion = "2023-07-01"

export function getSanityPreviewClient(
  preview,
  previewDataset = envDataset
) {
  const client = createClient({
    projectId,
    dataset: previewDataset,
    apiVersion,
    useCdn: false, // to ensure data is fresh
    token: preview.token,
  })

  return client
}

export function getGatsbyImageDataFromSanity(
  sanityAssetObject,
  options,
  dataset = envDataset
) {
  if (typeof window === "undefined") {
    return
  }
  return getGatsbyImageData(sanityAssetObject, options, {
    projectId: projectId,
    dataset,
  })
}

