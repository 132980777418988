import "./src/styles/global.css"
import React from "react"
import { StoreContextProvider } from "./src/context/siteContext"
import { QueryClient, QueryClientProvider } from "react-query"
import { PreviewProvider } from "./src/context/PreviewContext"
import SanityPreviewConnectorProvider from "./src/components/Preview/SanityPreviewConnector"
import { reportError } from "./src/utils/reportError"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

export const wrapRootElement = ({ element }) => {
  if (typeof window !== "undefined") {
    window.addEventListener("error", error => {
      reportError(error)
    })
    window.addEventListener("unhandledrejection", error => {
      reportError(error)
    })
  }

  return (
    <PreviewProvider>
      <SanityPreviewConnectorProvider
        token={process.env.GATSBY_SANITY_PREVIEW_TOKEN}
      >
        <QueryClientProvider client={queryClient}>
          <StoreContextProvider>{element}</StoreContextProvider>
        </QueryClientProvider>
      </SanityPreviewConnectorProvider>
    </PreviewProvider>
  )
}
