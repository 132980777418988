export const reportError = async errorOrEvent => {
  let errorEvent

  if (errorOrEvent instanceof Error) {
    // Directly caught Error object
    const error = errorOrEvent
    errorEvent = {
      message: error.message,
      stack: error.stack,
      errorType: error.name,
      userAgent: navigator.userAgent,
      viewportSize: `${window.innerWidth}x${window.innerHeight}`,
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      timestamp: new Date().toISOString(),
      url: window.location.href,
      referrer: document.referrer
    }
  } else if (errorOrEvent.type === "error") {
    // Global error event object
    errorEvent = {
      message: errorOrEvent.message,
      stack: errorOrEvent.error?.stack || "",
      errorType: errorOrEvent.error?.name || "Error",
      userAgent: navigator.userAgent,
      viewportSize: `${window.innerWidth}x${window.innerHeight}`,
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      timestamp: new Date().toISOString(),
      url: window.location.href,
      referrer: document.referrer
    }
  } else if (errorOrEvent.type === "unhandledrejection") {
    let message = "",
      stack = ""
    if (typeof errorOrEvent.reason === "string") {
      message = errorOrEvent.reason
    } else if (errorOrEvent.reason instanceof Error) {
      message = errorOrEvent.reason.message
      stack = errorOrEvent.reason.stack
    }
    errorEvent = {
      message: message,
      stack: stack,
      errorType: "UnhandledRejection",
      userAgent: navigator.userAgent,
      viewportSize: `${window.innerWidth}x${window.innerHeight}`,
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      timestamp: new Date().toISOString(),
      url: window.location.href,
      referrer: document.referrer
    }
  } else {
    // Unsupported type
    console.error(
      "reportError was called with an unsupported type:",
      errorOrEvent
    )
    return
  }

  try {
    await fetch("/api/log-error", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ errorEvent })
    })
  } catch (fetchError) {
    console.error("Error reporting to server:", fetchError)
  }
}
